import AppleFilledIcon from '@/assets/icons/appleFilled.svg?react';
import GoogleIcon from '@/assets/icons/googleLogo.svg?react';
import AppleIcon from '@/assets/icons/icon-apple.svg?react';
import { useTheme } from '@/components/misc/providers/theme-provider';
import { useMutation } from '@tanstack/react-query';
import { Mutex } from 'async-mutex';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  type User,
} from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

const firebaseConfig = JSON.parse(
  import.meta.env.VITE_FIREBASE_CONFIG as string,
) as {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
export const oauthProviders = [
  {
    label: 'Google',
    provider: googleProvider,
    Icon: GoogleIcon,
  },
  {
    label: 'Apple',
    provider: appleProvider,
    Icon: ({ className }: { className?: string }) => {
      const { isDark } = useTheme();
      return isDark ? (
        <AppleFilledIcon className={className} />
      ) : (
        <AppleIcon className={className} />
      );
    },
  },
];

export const useFirebaseSignOut = () =>
  useMutation({ mutationFn: () => auth.signOut() });

export const useFirebaseUser = () => {
  const [user, setUser] = useState<User | null>(auth.currentUser);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((fbUser) => {
      setUser(fbUser);
    });
    return unsubscribe;
  }, [setUser]);
  return {
    setFirebaseUser: setUser,
    firebaseUser: user,
    linkedToGoogle:
      user?.providerData.some(
        (p) => p.providerId === 'google.com' && p.email,
      ) ?? false,
    linkedToApple:
      user?.providerData.some((p) => p.providerId === 'apple.com' && p.email) ??
      false,
  };
};

export type FirebaseErrorCode =
  // Copied from https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
  | 'auth/app-deleted'
  | 'auth/app-not-authorized'
  | 'auth/argument-error'
  | 'auth/invalid-api-key'
  | 'auth/invalid-user-token'
  | 'auth/invalid-tenant-id'
  | 'auth/network-request-failed'
  | 'auth/operation-not-allowed'
  | 'auth/requires-recent-login'
  | 'auth/too-many-requests'
  | 'auth/unauthorized-domain'
  | 'auth/user-disabled'
  | 'auth/user-token-expired'
  | 'auth/web-storage-unsupported'
  // Found in the wild
  | 'auth/popup-closed-by-user'
  | 'auth/credential-already-in-use';
export function refreshFirebaseTokenIfNeeded(
  setNewToken: (token: string) => void,
  jwt?: string,
) {
  const mutex = refreshFirebaseTokenIfNeeded.refreshMutex;
  if (!jwt) return;
  const decoded = jwtDecode<
    | {
        iss: string;
        user_id: string;
        exp: number;
        email: string;
      }
    | {
        email: string;
        isAuthorized: true;
        exp: number;
      }
  >(jwt);
  if (!('iss' in decoded)) return jwt;

  const expiringInSecs = decoded.exp - Date.now() / 1000;
  if (expiringInSecs > 60 * 3) return jwt;

  const firebaseUser = auth.currentUser;
  if (firebaseUser?.uid !== decoded.user_id) return jwt;
  if (mutex.isLocked()) return jwt;
  void mutex.runExclusive(async () => {
    await firebaseUser.getIdToken(true).then((newToken) => {
      setNewToken(newToken);
    });
  });
  return jwt;
}
refreshFirebaseTokenIfNeeded.refreshMutex = new Mutex();
