import { PaymentIntentDialogContext } from '@/components/plan/payment-intent-dialog-provider';
import { env } from '@/lib/env';
import { fetchUserSummaryOpts } from '@/services/user-service';
import { TZDate } from '@date-fns/tz';
import {
  logger,
  objectIdToTimestamp,
  useContextAndErrorIfNull,
  useUserStore,
} from '@listening/shared';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import { startOfWeek } from 'date-fns';
import { useEffect } from 'react';
import {
  cancelStripeSubscriptionReq,
  getPaymentDetailsReq,
  getSubscriptionsReq,
  modifyStripePaymentMethodReq,
  modifyStripeSubscriptionReq,
  postCancelSurveyReq,
  resubscribeStripeSubscriptionReq,
  successfulPaymentIntentReq,
} from './api-service';
import { audioService } from './audio-service';
import { useUserToken, validTokenGuard } from './user-service';

export const usePaymentSubscriptionsQuery = () => {
  const { token } = useUserToken();
  return useQuery({
    queryKey: [getSubscriptionsReq.name, token],
    queryFn: () => getSubscriptionsReq(validTokenGuard(token)),
    staleTime: 1000 * 60 * 60, // 1 hour
    retry: false,
  });
};
export const usePaymentDetailsQuery = () => {
  const { token } = useUserToken();
  return useQuery({
    queryKey: [getPaymentDetailsReq.name, token],
    queryFn: () => getPaymentDetailsReq(validTokenGuard(token)),
    retry: false,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

const useUserSummaryQuery = () => {
  const { token } = useUserToken();
  return useQuery(fetchUserSummaryOpts(token));
};

export const useIsInvalidSubscription = () => {
  const query = useUserSummaryQuery();
  return query.data?.metering.is_active_subscription === false;
};

export const useIsValidSubscription = () => {
  const query = useUserSummaryQuery();
  return query.data?.metering.is_active_subscription === true;
};

export const useCancelStripeMutation = () => {
  const { token } = useUserToken();
  const email = useUserStore((state) => state.user)?.email;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      cancelReason,
      likedFeatures,
    }: {
      cancelReason: string;
      likedFeatures: string;
    }) => {
      void postCancelSurveyReq(validTokenGuard(token), {
        email: validTokenGuard(email),
        is_user_deleted: false,
        user_cancel_reason: cancelReason,
        user_liked_features: likedFeatures,
      });
      await cancelStripeSubscriptionReq(validTokenGuard(token));
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      void queryClient.invalidateQueries(fetchUserSummaryOpts(token));

      logger.log('Stripe subscription canceled');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};
export const useModifyStripeSubscriptionMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (stripePriceId: string) => {
      await modifyStripeSubscriptionReq({
        token: validTokenGuard(token),
        stripePriceId,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name, token],
      });
      logger.log('Stripe subscription modified');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};
export const useModifyStripePaymentMethodMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();
  return useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        throw new Error('Not initialized yet');
      }

      await elements.submit();

      const paymentMethodResult = await stripe.createPaymentMethod({
        elements,
      });

      if (paymentMethodResult.error) {
        throw new Error(paymentMethodResult.error.message);
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;

      await modifyStripePaymentMethodReq({
        token: validTokenGuard(token),
        paymentMethodId,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      logger.log('Stripe payment method modified');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useResubscribeStripeMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const { setClientSecret } = useContextAndErrorIfNull(
    PaymentIntentDialogContext,
  );
  return useMutation({
    mutationFn: async (stripePriceId: string) => {
      return await resubscribeStripeSubscriptionReq({
        token: validTokenGuard(token),
        price_id: stripePriceId,
      });
    },
    onSuccess: (result) => {
      if (result.payment_intent_client_secret) {
        setClientSecret(result.payment_intent_client_secret);
      }
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      void queryClient.invalidateQueries(fetchUserSummaryOpts(token));

      void queryClient.invalidateQueries({
        queryKey: [getPaymentDetailsReq.name],
      });
      logger.log('Stripe subscription resubscribed');
    },
    onError: (error) => {
      logger.error('Resubscribe error', error);
    },
  });
};

const useReportPaymentIntentMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (paymentIntentId: string) => {
      await successfulPaymentIntentReq({
        token: validTokenGuard(token),
        payment_intent_id: paymentIntentId,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getSubscriptionsReq.name],
      });
      void queryClient.invalidateQueries(fetchUserSummaryOpts(token));

      void queryClient.invalidateQueries({
        queryKey: [getPaymentDetailsReq.name],
      });
      logger.log('Stripe payment intent reported');
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

export const useReportSuccessfulPaymentIntent = () => {
  const mutation = useReportPaymentIntentMutation();

  const { mutate, isPending } = mutation;
  const search: {
    payment_intent?: string;
    setup_intent?: string;
  } = useSearch({ strict: false });
  useEffect(() => {
    if (search.payment_intent && !isPending) {
      logger.log(
        'Reporting successful payment intent to backend',
        search.payment_intent,
      );
      mutate(search.payment_intent);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [isPending, mutate, search]);
  const queryClient = useQueryClient();
  const { token } = useUserToken();
  useEffect(() => {
    if (search.setup_intent) {
      window.history.replaceState({}, '', window.location.pathname);
      void queryClient.invalidateQueries(fetchUserSummaryOpts(token));
    }
  }, [queryClient, search.setup_intent, token]);
  return mutation;
};

export type OfferedStripePlan = 'monthly-19' | 'yearly-125';

export const offeredPlanToPriceId: Record<OfferedStripePlan, string> = {
  'monthly-19': env.VITE_STRIPE_MONTHLY_19_ID,
  'yearly-125': env.VITE_STRIPE_YEARLY_125_ID,
};
export const priceIdToOfferedPlan: Record<string, OfferedStripePlan> = {
  [env.VITE_STRIPE_MONTHLY_19_ID]: 'monthly-19',
  [env.VITE_STRIPE_YEARLY_125_ID]: 'yearly-125',
};

export const useFreePlanUploadLimit = () => {
  const listAudioQuery = audioService.primaryQueries.useListAudioQuery({
    status: undefined,
    except_status: 'Error',
  });

  const audioAndQueue =
    listAudioQuery.data?.pages.map((page) => page.audio_conversions).flat() ??
    [];
  const currentDatePT = new TZDate(new Date(), 'America/Los_Angeles');
  const lastReset = startOfWeek(currentDatePT, { weekStartsOn: 1 });

  const uploadsSinceReset = audioAndQueue.filter(
    (audio) => objectIdToTimestamp(audio.id) > lastReset,
  );
  const maxUploads = 3;
  const remainingUploads = Math.max(maxUploads - uploadsSinceReset.length, 0);
  const isSubscriptionInvalid = useIsInvalidSubscription();

  return {
    remainingUploads,
    maxUploads,
    overUploadLimit: remainingUploads <= 0 && isSubscriptionInvalid,
    limitEnabled: isSubscriptionInvalid,
  };
};

export const useIsUserAllowedToResubscribeToStripe = () => {
  // User cannot resubscribe if:
  // - They have an active stripe subscription
  // - They have an active mobile subscription
  const subscriptionsQuery = usePaymentSubscriptionsQuery();

  if (!subscriptionsQuery.data) return 'no-data';

  const rcStatus = subscriptionsQuery.data.revenue_cat_subscription?.status;
  const stripeStatus = subscriptionsQuery.data.stripe_subscription?.status;

  const hasActiveMobileSubscription =
    rcStatus === 'active' || rcStatus === 'trialing';
  const hasActiveStripeSubscription =
    stripeStatus === 'active' || stripeStatus === 'trialing';

  return hasActiveMobileSubscription || hasActiveStripeSubscription
    ? 'no'
    : 'yes';
};
