/* eslint-disable @typescript-eslint/no-unsafe-assignment -- good ole env parsing */
import { z } from 'zod';

export const env = z
  .object({
    // Vite
    MODE: z.string(),
    DEV: z.boolean(),
    SSR: z.boolean(),
    // Network Config
    VITE_BACKEND_URL: z.string(),
    VITE_ASTRO_URL: z.string(),
    // Book keeping
    VITE_COMMIT_HASH: z.string(),
    // API Keys
    VITE_FIREBASE_CONFIG: z.string(),
    VITE_STRIPE_PUBLIC_KEY: z.string(),
    VITE_AMPLITUDE_API_KEY: z.string(),
    VITE_SENTRY_DSN: z.string(),
    VITE_CUSTOMERIO_WRITE_KEY: z.string(),
    VITE_CUSTOMERIO_SITE_ID: z.string(),
    // Stripe
    VITE_STRIPE_YEARLY_125_ID: z.string(),
    VITE_STRIPE_YEARLY_99_ID: z.string(),
    VITE_STRIPE_YEARLY_69_ID: z.string(),
    VITE_STRIPE_YEARLY_59_ID: z.string(),
    VITE_STRIPE_MONTHLY_19_ID: z.string(),
    VITE_STRIPE_MONTHLY_12_ID: z.string(),
  })
  .parse({
    MODE: import.meta.env.MODE,
    DEV: import.meta.env.DEV,
    SSR: import.meta.env.SSR,
    // Network Config
    VITE_BACKEND_URL: import.meta.env.VITE_BACKEND_URL,
    VITE_ASTRO_URL: import.meta.env.VITE_ASTRO_URL,
    // Book keeping
    VITE_COMMIT_HASH: import.meta.env.VITE_COMMIT_HASH,
    // API Keys
    VITE_FIREBASE_CONFIG: import.meta.env.VITE_FIREBASE_CONFIG,
    VITE_STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
    VITE_AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    VITE_CUSTOMERIO_WRITE_KEY: import.meta.env.VITE_CUSTOMERIO_WRITE_KEY,
    VITE_CUSTOMERIO_SITE_ID: import.meta.env.VITE_CUSTOMERIO_SITE_ID,
    // Stripe
    VITE_STRIPE_YEARLY_125_ID: import.meta.env.VITE_STRIPE_YEARLY_125_ID,
    VITE_STRIPE_YEARLY_99_ID: import.meta.env.VITE_STRIPE_YEARLY_99_ID,
    VITE_STRIPE_YEARLY_69_ID: import.meta.env.VITE_STRIPE_YEARLY_69_ID,
    VITE_STRIPE_YEARLY_59_ID: import.meta.env.VITE_STRIPE_YEARLY_59_ID,
    VITE_STRIPE_MONTHLY_19_ID: import.meta.env.VITE_STRIPE_MONTHLY_19_ID,
    VITE_STRIPE_MONTHLY_12_ID: import.meta.env.VITE_STRIPE_MONTHLY_12_ID,
  });
