export function LegacyMessage() {
  return (
    <div className="h-full bg-white p-6 text-center font-sans">
      <h2 className="mb-4 text-2xl font-bold text-gray-800">
        Important Update
      </h2>
      <p className="mb-6 text-lg text-gray-700">
        We’ve moved to the Chrome Web Store! Please install the official version
        and uninstall this one for the best experience.
      </p>
      <a
        href="https://chromewebstore.google.com/detail/listeningcom/amjjglmomeodglgclcnjdjbhhcfnbnpd"
        className="inline-block rounded-md bg-blue-600 px-4 py-2 font-medium text-white hover:bg-blue-700"
        target="_blank"
        rel="noreferrer"
      >
        Install New Version
      </a>
    </div>
  );
}
