import { env } from '@/lib/env';
import {
  getDefaultPaymentPlans,
  getSpecialPaymentPlans,
} from '@listening/shared';

export const defaultPaymentPlans = getDefaultPaymentPlans(env);
export const specialPaymentPlans = getSpecialPaymentPlans(env);

export const specialPlanPromoCodes = Object.keys(specialPaymentPlans);
