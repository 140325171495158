import { createContext, useState } from 'react';
import { PaymentIntentDialog } from '../dialogs/payment-intent-dialog';
import { Dialog } from '../ui/dialog';

// eslint-disable-next-line react-refresh/only-export-components -- ack
export const PaymentIntentDialogContext = createContext<{
  clientSecret: string | null;
  setClientSecret: (clientSecret: string) => void;
} | null>(null);

export function PaymentIntentDialogProvider({
  children,
}: React.PropsWithChildren) {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  return (
    <PaymentIntentDialogContext.Provider
      value={{
        clientSecret,
        setClientSecret,
      }}
    >
      <Dialog
        open={!!clientSecret}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setClientSecret(null);
          }
        }}
      >
        <PaymentIntentDialog
          clientSecret={clientSecret}
          close={() => {
            setClientSecret(null);
          }}
        />
      </Dialog>
      {children}
    </PaymentIntentDialogContext.Provider>
  );
}
