import LinkIcon from '@/assets/icons/link.svg?react';
import LogoutIcon from '@/assets/icons/logout.svg?react';
import PageIcon from '@/assets/icons/page.svg?react';
import PlanIcon from '@/assets/icons/plan.svg?react';
import ProfileIcon from '@/assets/icons/profile.svg?react';
import ReferIcon from '@/assets/icons/refer.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';

import { useLogoutAndClearCache } from '@/hooks/login-logout.hook';

import { DialogDescription, DialogTitle } from '@/components/ui/dialog';
import { VisuallyHidden } from '@/components/ui/visually-hidden';
import { audioService } from '@/services/audio-service';
import { useContextAndErrorIfNull } from '@listening/shared';
import type { LinkOptions } from '@tanstack/react-router';
import { Link, useNavigate } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { CoverImgWithBackgroundNew } from '../../misc/cover-img-with-background';
import { useLoadAudio } from '../../misc/providers/audio-load-provider';
import { useUploadDialogs } from '../../misc/providers/upload-dialog-providers';

const ModalCmdKContext = createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
} | null>(null);

// eslint-disable-next-line react-refresh/only-export-components -- fast refresh not needed
export const useModalCmdK = () => useContextAndErrorIfNull(ModalCmdKContext);

export function ModalCmdKProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  useHotkeys(
    ['ctrl+k', 'meta+k'],
    () => {
      setOpen(!open);
    },
    {
      preventDefault: true,
    },
    [setOpen],
  );

  return (
    <ModalCmdKContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      {children}
      <ModalCmdK />
    </ModalCmdKContext.Provider>
  );
}

function ModalCmdK() {
  const { open, setOpen } = useContextAndErrorIfNull(ModalCmdKContext);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const logout = useLogoutAndClearCache();
  const { setCurrentOpenDialog } = useUploadDialogs();

  const listAudioQuery = audioService.primaryQueries.useListAudioQuery();
  const audioListItems =
    listAudioQuery.data?.pages.map((page) => page.audio_conversions).flat() ??
    [];
  const loadAudio = useLoadAudio();

  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <VisuallyHidden>
          <DialogTitle>Cmd K Dialog</DialogTitle>
          <DialogDescription>Find things fast</DialogDescription>
        </VisuallyHidden>
        <CommandInput
          placeholder="Looking for something?"
          wrapperClassName="p-5"
          iconClassName="size-6"
          className="text-base"
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Audio">
            {audioListItems.map((audio) => (
              <CommandItem
                value={audio.id}
                key={audio.id}
                onSelect={() => {
                  void loadAudio(audio);
                  closeDialog();
                }}
              >
                <div className="mr-2 size-12 shrink-0 overflow-hidden rounded-lg">
                  <CoverImgWithBackgroundNew
                    coverImg={audio.cover_img_url}
                    size="sm"
                    title={audio.title}
                  />
                </div>
                <p className="">{audio.title}</p>
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Upload">
            <CommandItem
              onSelect={() => {
                closeDialog();
                setCurrentOpenDialog('file');
              }}
            >
              <PageIcon className="mr-2 size-4" />
              <span>Upload a file</span>
            </CommandItem>
            <CommandItem
              onSelect={() => {
                closeDialog();
                setCurrentOpenDialog('url');
              }}
            >
              <LinkIcon className="mr-2 size-4" />
              <span>Link to web page or PDF</span>
            </CommandItem>
            <CommandItem
              onSelect={() => {
                closeDialog();
                setCurrentOpenDialog('email');
              }}
            >
              <ReferIcon className="mr-2 size-4" />
              <span>Forward an email</span>
            </CommandItem>
          </CommandGroup>
          <CommandGroup heading="Settings">
            <LinkCommandItem closeDialog={closeDialog} to="/profile">
              <ProfileIcon className="mr-2 size-4" />
              <span>Profile</span>
            </LinkCommandItem>
            <LinkCommandItem closeDialog={closeDialog} to="/plan">
              <PlanIcon className="mr-2 size-4" />
              <span>Plan</span>
            </LinkCommandItem>
            <LinkCommandItem closeDialog={closeDialog} to="/settings">
              <SettingsIcon className="mr-2 size-4" />
              <span>Settings</span>
            </LinkCommandItem>
            <CommandItem
              onSelect={() => {
                logout();
              }}
            >
              <LogoutIcon className="mr-2 size-4" />
              <span>Log out</span>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
}

function LinkCommandItem({
  closeDialog,
  ...props
}: { closeDialog: () => void } & LinkOptions & PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <CommandItem
      onSelect={() => {
        void navigate({ to: props.to });
        closeDialog();
      }}
      asChild
    >
      <Link {...props}>{props.children}</Link>
    </CommandItem>
  );
}
