import { isUserLoggedIn } from '@/services/user-service';
import { logger } from '@listening/shared';
import type { createFileRoute } from '@tanstack/react-router';
import { redirect } from '@tanstack/react-router';
import { z } from 'zod';

const authSearchParamsSchema = z.object({
  // We don't want to show an error if seach is malformed
  // https://tanstack.com/router/latest/docs/framework/react/guide/search-params#validating-search-params
  redirectTo: z.string().optional().catch(undefined),
});

function authBeforeLoad() {
  if (isUserLoggedIn()) {
    logger.log('User already logged in, redirect to home');
    return redirect({
      to: '/home',
    });
  }
}

export const authRouteOptions = () =>
  ({
    validateSearch: authSearchParamsSchema,
    beforeLoad: authBeforeLoad,
  }) satisfies Parameters<ReturnType<typeof createFileRoute>>[0];
