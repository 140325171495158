import { PremiumDialog } from '@/components/dialogs/premium-dialog';
import { Dialog } from '@/components/ui/dialog';
import { useContextAndErrorIfNull } from '@listening/shared';
import React, { createContext } from 'react';

export type PremiumGate =
  | 'playback_speed'
  | 'voice'
  | 'upload_limit'
  | 'file_type';
const PremiumDialogContext = createContext<{
  currentPremiumDialog: PremiumGate | null;
  setCurrentPremiumDialog: (dialog: PremiumGate | null) => void;
} | null>(null);

export function PremiumDialogsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentPremiumDialog, setCurrentPremiumDialog] =
    React.useState<PremiumGate | null>(null);

  return (
    <PremiumDialogContext.Provider
      value={{
        currentPremiumDialog,
        setCurrentPremiumDialog,
      }}
    >
      <Dialog
        open={currentPremiumDialog != null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setCurrentPremiumDialog(null);
          }
        }}
      >
        {children}
        {currentPremiumDialog && <PremiumDialog gate={currentPremiumDialog} />}
      </Dialog>
    </PremiumDialogContext.Provider>
  );
}
// eslint-disable-next-line react-refresh/only-export-components -- I know the risks
export function usePremiumDialog() {
  const context = useContextAndErrorIfNull(PremiumDialogContext);
  return context;
}
