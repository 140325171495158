import { stripePromise } from '@/lib/stripe-utils';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useMutation } from '@tanstack/react-query';
import type { FormEvent } from 'react';
import { toast } from 'sonner';
import { useTheme } from '../misc/providers/theme-provider';
import Spinner from '../misc/spinner';
import { Button } from '../ui/button';
import { DialogContent } from '../ui/dialog';

export function PaymentIntentDialog({
  clientSecret,
  close,
}: {
  clientSecret: string | null;
  close: () => void;
}) {
  const { isDark } = useTheme();
  if (!clientSecret) {
    return <></>;
  }

  return (
    <DialogContent>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance: {
            theme: isDark ? 'night' : 'stripe',

            variables: {
              borderRadius: '10px',
            },
          },
        }}
      >
        <StripePaymentIntent clientSecret={clientSecret} close={close} />
      </Elements>
    </DialogContent>
  );
}

function StripePaymentIntent({
  clientSecret,
  close,
}: {
  clientSecret: string;
  close: () => void;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const mutation = useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        throw new Error('Not initialized yet');
      }

      await elements.submit();

      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: window.location.href,
        },
      });
      if (result.error as { message: string } | null) {
        throw new Error(result.error.message);
      }
    },
  });
  const disabled = !stripe || !elements || mutation.isPending;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate(undefined, {
      onSuccess: () => {
        toast.success('Subscribed');
        close();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <PaymentElement
        options={
          {
            //   layout: 'accordion',
          }
        }
      />
      {mutation.error && (
        <div role="alert" className="text-red-500">
          {mutation.error.message}
        </div>
      )}
      <Button
        type="submit"
        disabled={disabled}
        className="flex max-w-48 gap-1 rounded-full"
      >
        {mutation.isPending && <Spinner />}
        Submit
      </Button>
    </form>
  );
}
