/* eslint-disable react-refresh/only-export-components -- ui component */
// https://ui.shadcn.com/docs/components/dialog
// https://github.com/radix-ui/primitives/issues/1836
// https://codesandbox.io/embed/r9sq1q
import { useContextAndErrorIfNull } from '@listening/shared';
import React, { createContext } from 'react';
import { Dialog, DialogTrigger } from './dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu';

const DialogDropDownContext = createContext<{
  itemSelected: () => void;
  dialogOpenChanged: (open: boolean) => void;
} | null>(null);

export function DialogDropdown({
  TriggerButton,
  className,
  children,
}: {
  TriggerButton: React.ForwardRefExoticComponent<
    React.RefAttributes<HTMLButtonElement>
  >;
  className?: string;
  children?: React.ReactNode;
}) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false);

  const dropdownTriggerRef = React.useRef<HTMLButtonElement | null>(null);
  const focusRef = React.useRef<HTMLButtonElement | null>(null);

  const dialogItemSelected = () => {
    focusRef.current = dropdownTriggerRef.current;
  };

  const dialogItemOpenChanged = (open: boolean) => {
    setHasOpenDialog(open);
    if (!open) setDropdownOpen(false);
  };

  return (
    <DropdownMenu
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
      modal={false}
    >
      <DropdownMenuTrigger asChild>
        <TriggerButton ref={dropdownTriggerRef} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={className}
        hidden={hasOpenDialog}
        onCloseAutoFocus={(e) => {
          if (focusRef.current) {
            focusRef.current.focus();
            focusRef.current = null;
            e.preventDefault();
          }
        }}
      >
        <DialogDropDownContext.Provider
          value={{
            itemSelected: dialogItemSelected,
            dialogOpenChanged: dialogItemOpenChanged,
          }}
        >
          {children}
        </DialogDropDownContext.Provider>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function DialogItem({
  dialogContent,
  children,
  className,
}: {
  dialogContent: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  const [open, setOpen] = React.useState(false);
  const { itemSelected, dialogOpenChanged } = useContextAndErrorIfNull(
    DialogDropDownContext,
  );

  const closeDialog = React.useCallback(() => {
    setOpen(false);
    dialogOpenChanged(false);
  }, [setOpen, dialogOpenChanged]);

  return (
    <DialogCloseContext.Provider value={{ closeDialog, open }}>
      <Dialog
        open={open}
        onOpenChange={(val) => {
          setOpen(val);
          dialogOpenChanged(val);
        }}
      >
        <DialogTrigger asChild>
          <DropdownMenuItem
            className={className}
            onSelect={(e) => {
              e.preventDefault();
              itemSelected();
            }}
          >
            {children}
          </DropdownMenuItem>
        </DialogTrigger>
        {dialogContent}
      </Dialog>
    </DialogCloseContext.Provider>
  );
}

export const DialogCloseContext = React.createContext<{
  closeDialog: () => void;
  open?: boolean;
}>({ closeDialog: () => null });

/**
 * Provides a context for managing the state of a dialog close event.
 * This is an ugly hack. Radix UI does not provide a way to close a dialog from a child component
 * without making the Dialog a controlled component. This is a workaround to allow a child component
 * to close a dialog without making the Dialog a controlled component.
 *
 * @param children - The content to be rendered inside the DialogCloseProvider.
 * @returns The DialogCloseProvider component.
 */
export function DialogCloseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <DialogCloseContext.Provider value={{ closeDialog, open }}>
      <Dialog
        open={open}
        onOpenChange={(val) => {
          setOpen(val);
        }}
      >
        {children}
      </Dialog>
    </DialogCloseContext.Provider>
  );
}
