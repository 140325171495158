import FilesIcon from '@/assets/icons/files.svg?react';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated/_dashboard/files/$folderId',
)({
  beforeLoad: () => ({
    title: 'Folder',
    icon: FilesIcon,
  }),
});
