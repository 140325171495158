import { delayMs } from '@listening/shared';
import { useEffect, useState } from 'react';
import { env } from './env';

export function isError(err: unknown): err is Error {
  return err instanceof Error;
}

const IS_TAURI = '__TAURI__' in window;

export const envString = `${env.MODE}${IS_TAURI ? `-tauri` : ''}`;

export const waitForElement = async (selector: string, timeout = 5000) => {
  const start = Date.now();
  while (Date.now() - start < timeout) {
    const element = document.querySelector(selector);
    if (element) return element;
    await delayMs(100);
  }
  return null;
};

export function stringToNumberBlurHash(str: string) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
}

export function formatTimeSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const useElementOnScreen = (options?: IntersectionObserverInit) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (ref) {
      observer = new IntersectionObserver(([entry]) => {
        if (!entry) return;
        setIsVisible(entry.isIntersecting);
      }, options);

      observer.observe(ref);
    }

    return () => {
      if (observer && ref) {
        observer.unobserve(ref);
        observer.disconnect();
      }
    };
  }, [ref, options]);

  return [setRef, isVisible] as const;
};

export function getContrastColor(hexColor: string): string {
  // Remove the hash at the start if it's there
  hexColor = hexColor.replace('#', '');

  // Parse the r, g, b values
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the luminance
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black for light colors and white for dark colors
  return yiq >= 128 ? '#000000' : '#FFFFFF';
}
