import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';

import { cn } from '@listening/shared';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    thumbClassName?: string | null;
    rangeClassName?: string;
    trackClassName?: string;
    rangeComponent?: React.ReactNode;
    trackChildren?: React.ReactNode;
  }
>(
  (
    {
      className,
      thumbClassName,
      rangeClassName,
      trackClassName,
      rangeComponent,
      trackChildren,
      ...props
    },
    ref,
  ) => (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex w-full touch-none select-none items-center',
        className,
      )}
      {...props}
    >
      <SliderPrimitive.Track
        className={cn(
          'relative h-2 w-full grow overflow-hidden rounded-full bg-secondary',
          trackClassName,
        )}
      >
        {rangeComponent ? (
          // Render rangeComponent if it's provided
          <SliderPrimitive.Range
            className={cn('absolute h-full', rangeClassName)}
            asChild
          >
            <span className="overflow-hidden">{rangeComponent}</span>
          </SliderPrimitive.Range>
        ) : (
          // Fall back to the default range if rangeComponent is not provided
          <SliderPrimitive.Range
            className={cn('absolute h-full bg-primary', rangeClassName)}
          />
        )}
        {trackChildren}
      </SliderPrimitive.Track>
      {thumbClassName !== null && (
        <SliderPrimitive.Thumb
          className={cn(
            'block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
            thumbClassName,
          )}
        />
      )}
    </SliderPrimitive.Root>
  ),
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
