import { env } from '@/lib/env';
import { create } from 'zustand';
import type { SubscriptionSummaryDTO } from './api-service';

const possibleMocks = [
  'mock_disabled',
  'no_sub',
  'stripe1yr',
  // 'stripe5y',
  //   'stripeLegacyMo',
  //   'stripeLegacyYr',
  'stripe1yrExpired',
  //   'stripe5yrExpired',
  'stripe1yrCanceled',
  // 'stripe5yrCanceled',
  'rc1yr',
  //   'rcLegacyYr',
  //   'rcLegacyMo',
  //   'rc1yrExpired',
  'rc1yrCanceled',
  'stripe1yrAndRc1yr',
  'stripe1yrAndRc1yrStripeCanceled',
  'stripe1yrAndRc1yrRCCanceled',
  'stripe1yrAndRc1yrBothCanceled',
] as const;
type SubscriptionSummarySpecialCases = (typeof possibleMocks)[number];
type MockSubStore = {
  chosenMock: SubscriptionSummarySpecialCases;
  setChosenMock: (mock: SubscriptionSummarySpecialCases) => void;
  possibleMocks: typeof possibleMocks;
  mockNextPaymentDate?: Date;
  setMockNextPaymentDate: (date?: Date) => void;
  isSubscriptionValid: boolean;
  setIsSubscriptionValid: (isInvalid: boolean) => void;
};
export const useMockSubStore = create<MockSubStore>()((set) => ({
  chosenMock: 'mock_disabled',
  setChosenMock: (mock) => {
    set({ chosenMock: mock });
  },
  possibleMocks,
  mockNextPaymentDate: undefined,
  setMockNextPaymentDate: (date?: Date) => {
    set({ mockNextPaymentDate: date });
  },
  isSubscriptionValid: false,
  setIsSubscriptionValid: (isInvalid) => {
    set({ isSubscriptionValid: isInvalid });
  },
}));

const baseStripe: SubscriptionSummaryDTO['stripe_subscription'] = {
  canceled_date: null,
  default_amount_cents: 13900,
  default_interval: 'year',
  default_interval_count: 1,
  has_active_schedule: false,
  price_id: env.VITE_STRIPE_YEARLY_99_ID,
  status: 'trialing',
  payment_method: {
    card: {
      brand: 'Visa',
      exp_month: 12,
      exp_year: 2023,
      last_4: '4242',
    },
    created: '2024-03-26',
    type: 'card',
  },
  current_payment_phase: {
    end_date: '2024-03-26',
    start_date: '2023-03-26',
    is_trial: true,
  },
  next_payment_phase: null,
  latest_invoice_payment_intent_client_secret: null,
};

const canceledStripe: SubscriptionSummaryDTO['stripe_subscription'] = {
  ...baseStripe,
  status: 'canceled',
  canceled_date: '2024-03-26',
};

const baseRC: SubscriptionSummaryDTO['revenue_cat_subscription'] = {
  canceled_date: null,
  management_url: 'https://apps.apple.com/account/subscriptions',
  next_payment_date: '2024-02-20',
  product: {
    default_amount_cents: 13999,
    default_interval: 'year',
    default_interval_count: 1,
    id: 'v3_listening_13999_1y_2w0',
  },
  status: 'trialing',
};

const canceledRC: SubscriptionSummaryDTO['revenue_cat_subscription'] = {
  ...baseRC,
  status: 'canceled',
  canceled_date: '2024-03-26',
  management_url: null,
};

export const mockSubscriptionSummaryResponses: Record<
  SubscriptionSummarySpecialCases,
  SubscriptionSummaryDTO
> = {
  mock_disabled: {
    revenue_cat_subscription: null,
    stripe_subscription: null,
    referral_subscription: null,
  },
  no_sub: {
    revenue_cat_subscription: null,
    stripe_subscription: null,
    referral_subscription: null,
  },
  stripe1yr: {
    revenue_cat_subscription: null,
    stripe_subscription: baseStripe,
    referral_subscription: null,
  },
  stripe1yrExpired: {
    revenue_cat_subscription: null,
    stripe_subscription: { ...canceledStripe, status: 'unpaid' },
    referral_subscription: null,
  },
  rc1yr: {
    revenue_cat_subscription: baseRC,
    stripe_subscription: null,
    referral_subscription: null,
  },
  stripe1yrAndRc1yr: {
    revenue_cat_subscription: baseRC,
    stripe_subscription: baseStripe,
    referral_subscription: null,
  },
  stripe1yrCanceled: {
    revenue_cat_subscription: null,
    stripe_subscription: canceledStripe,
    referral_subscription: null,
  },
  rc1yrCanceled: {
    stripe_subscription: null,
    referral_subscription: null,
    revenue_cat_subscription: canceledRC,
  },
  stripe1yrAndRc1yrStripeCanceled: {
    stripe_subscription: canceledStripe,
    referral_subscription: null,
    revenue_cat_subscription: baseRC,
  },
  stripe1yrAndRc1yrBothCanceled: {
    stripe_subscription: canceledStripe,
    referral_subscription: null,
    revenue_cat_subscription: canceledRC,
  },
  stripe1yrAndRc1yrRCCanceled: {
    stripe_subscription: baseStripe,
    referral_subscription: null,
    revenue_cat_subscription: canceledRC,
  },
};
