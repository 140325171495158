import PlaybackSpeedIcon from '@/assets/icons/playbackSpeed.svg?react';
import PremiumLockIcon from '@/assets/icons/premium-lock.svg?react';
import { analyticsService } from '@/services/analytics-service';
import { audioService } from '@/services/audio-service';
import { useIsInvalidSubscription } from '@/services/subscription-service';
import { clamp, cn } from '@listening/shared';
import { useCallback, useEffect, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { usePremiumDialog } from '../misc/providers/premium-dialog-provider';
import { Button } from '../ui/button';
import { DropdownMenuContent } from '../ui/dropdown-menu';
import { Separator } from '../ui/separator';
import { Slider } from '../ui/slider';
import { Toggle } from '../ui/toggle';

const MIN_RATE = 0.25;
const MAX_RATE = 4.0;

const FREE_LIMIT = 1.25;

function convertValueToPercentage(value: number, min: number, max: number) {
  const maxSteps = max - min;
  const percentPerStep = 100 / maxSteps;
  const percentage = percentPerStep * (value - min);
  return clamp(percentage, 0, 100);
}

function useSetPlaybackRate() {
  const setPlaybackRate = audioService.store.useLocalPrefsStore(
    (state) => state.setPlaybackRate,
  );
  const playbackRate = audioService.store.useLocalPrefsStore(
    (state) => state.playbackRate,
  );
  const isSubscriptionInvalid = useIsInvalidSubscription();
  const { setCurrentPremiumDialog } = usePremiumDialog();

  const { setRate } = useGlobalAudioPlayer();
  const setFn = useCallback(
    (rate: number) => {
      if (rate < MIN_RATE) rate = MIN_RATE;
      if (rate > MAX_RATE) rate = MAX_RATE;
      if (rate > FREE_LIMIT && isSubscriptionInvalid) {
        setCurrentPremiumDialog('playback_speed');
        return;
      }
      setPlaybackRate(rate);
      setRate(rate);
      analyticsService.trackLogSetPlaybackSpeed({ rate });
    },
    [isSubscriptionInvalid, setCurrentPremiumDialog, setPlaybackRate, setRate],
  );
  return [playbackRate, setFn] as const;
}

export function PlaybackSpeedDropdown() {
  const [playbackRate, setAndSavePlaybackRate] = useSetPlaybackRate();
  const [tempPlaybackRate, setTempPlaybackRate] = useState(playbackRate);
  useEffect(() => {
    setTempPlaybackRate(playbackRate);
  }, [playbackRate]);
  const { setCurrentPremiumDialog } = usePremiumDialog();
  const isSubscriptionInvalid = useIsInvalidSubscription();

  return (
    <DropdownMenuContent className="flex flex-col rounded-3xl">
      <div className="flex gap-4 px-5 py-6">
        <PlaybackSpeedIcon className="text-[#344054] dark:text-white" />
        <span className="text-base font-semibold text-[#344054] dark:text-white">
          Speed
        </span>
      </div>
      <Separator />
      <div className="flex flex-col gap-4 px-5 py-6">
        <div className="flex gap-2">
          <Button
            variant="ghost"
            className="size-8 rounded-full"
            onClick={() => {
              setAndSavePlaybackRate(playbackRate - 0.05);
            }}
          >
            <span className="text-2xl">-</span>
          </Button>
          <div className="relative flex w-full items-center">
            <Slider
              value={[tempPlaybackRate]}
              min={MIN_RATE}
              max={MAX_RATE}
              step={0.05}
              onValueChange={(val) => {
                const value = val[0];
                if (value === undefined) return;
                if (value > FREE_LIMIT && isSubscriptionInvalid) return;
                setTempPlaybackRate(value);
              }}
              onValueCommit={(val) => {
                const value = val[0];
                if (value === undefined) return;
                setAndSavePlaybackRate(value);
              }}
              rangeClassName="rounded-l-full"
              trackClassName="overflow-visible"
              trackChildren={
                isSubscriptionInvalid && (
                  <div
                    className="absolute h-full rounded-full bg-[#F7CA4F33]"
                    style={{
                      right: '0px',
                      left:
                        convertValueToPercentage(
                          1.5,
                          MIN_RATE,
                          MAX_RATE,
                        ).toString() + '%',
                    }}
                  >
                    <PremiumLockIcon
                      className="pointer-events-none absolute -translate-x-1/2 -translate-y-1/2 transform"
                      style={{
                        left: '0%',
                      }}
                    />
                  </div>
                )
              }
            />
          </div>
          <Button
            variant="ghost"
            className="size-8 rounded-full"
            onClick={() => {
              setAndSavePlaybackRate(playbackRate + 0.05);
            }}
          >
            <span className="text-2xl">+</span>
          </Button>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex">
            <ToggleSpeedButton val={0.75} />
            <ToggleSpeedButton val={1.0} />
            <ToggleSpeedButton val={1.1} precision={3} />
            <ToggleSpeedButton val={1.15} precision={3} />
            <ToggleSpeedButton val={1.25} precision={3} />
          </div>
          <div
            className={cn(
              'flex items-center rounded-lg',
              isSubscriptionInvalid && 'bg-[#F6CA4F1A]',
            )}
          >
            <ToggleSpeedButton val={1.5} />
            <ToggleSpeedButton val={1.75} precision={3} />
            <ToggleSpeedButton val={1.85} precision={3} />
            <ToggleSpeedButton val={2.5} />
            {isSubscriptionInvalid && (
              <div className="flex w-16 items-center justify-center">
                <div className="flex items-center justify-center rounded-full bg-[#F7CA4F1A] p-[6px]">
                  <div className="flex items-center justify-center rounded-full bg-[#F7CA4F26] p-[6px]">
                    <PremiumLockIcon />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isSubscriptionInvalid && (
          <Button
            onClick={() => {
              setCurrentPremiumDialog('playback_speed');
            }}
          >
            Listen up to 4x speed with Premium
          </Button>
        )}
      </div>
    </DropdownMenuContent>
  );
}

function ToggleSpeedButton({
  val,
  precision,
}: {
  val: number;
  precision?: number;
}) {
  const [playbackRate, setAndSavePlaybackRate] = useSetPlaybackRate();

  return (
    <Toggle
      className="w-16"
      pressed={playbackRate.toFixed(2) == val.toFixed(2)}
      onClick={() => {
        setAndSavePlaybackRate(val);
      }}
    >
      {val.toPrecision(precision ?? 2)}
    </Toggle>
  );
}
