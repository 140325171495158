import { env } from '@/lib/env';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const envFlags = {
  // Default on
  SHOW_BACK2SCHOOL_PAGE: true,
  SHOW_BLACKFRIDAY24_PAGE: true,
  SHOW_FOLDERS: true,
  // Env dependent
  FEATURE_FLAG_DEVTOOLS: env.MODE !== 'production',
  // Default off
  FAST_DARK_MODE_SWITCH: false,
  TANSTACK_QUERY_DEVTOOLS: false,
  TANSTACK_ROUTER_DEVTOOLS: false,
  MOCK_SUBSCRIPTION_ENDPOINT: false,
  SHOW_REFER_A_FRIEND: false,
};
export type FeatureFlag = keyof typeof envFlags;
type FeatureFlagTag =
  | 'Dev Tool'
  | 'Needs UI Design'
  | 'Needs Backend'
  | 'Needs Frontend'
  | 'Ready For Review'
  | 'In Staging'
  | 'Disabled';

export const featureFlagTagColors: Record<FeatureFlagTag, string> = {
  'Dev Tool': 'bg-purple-200',
  'Needs UI Design': 'bg-yellow-200',
  'Needs Backend': 'bg-red-200',
  'Needs Frontend': 'bg-blue-200',
  'Ready For Review': 'bg-green-200',
  'In Staging': 'bg-green-200',
  Disabled: 'bg-gray-200',
};

type FeatureFlagStore = {
  flags: typeof envFlags;
  setFlag: (_flag: FeatureFlag, _value: boolean) => void;
  reset: () => void;
};

export const useFeatureFlagStore = create<FeatureFlagStore>()(
  persist(
    (set) => ({
      flags: envFlags,
      setFlag: (flag: FeatureFlag, value: boolean) => {
        set((state) => ({
          ...state,
          flags: {
            ...state.flags,
            [flag]: value,
          },
        }));
      },
      reset: () => {
        set({ flags: envFlags });
      },
    }),
    {
      name: 'feature-flag-storage',
      version: 2,
    },
  ),
);

export const useFeatureFlag = (flag: FeatureFlag) => {
  const { flags, setFlag } = useFeatureFlagStore();
  return [
    flags[flag],
    (value: boolean) => {
      setFlag(flag, value);
    },
  ] as const;
};
