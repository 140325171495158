import ChevronLeft from '@/assets/icons/chevronLeft.svg?react';
import StarIcon from '@/assets/icons/star.svg?react';
import annaVPng from '@/assets/imgs/anna-v.png';

import jayatiPng from '@/assets/imgs/Jayati-S.png';
import { Button } from '@/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  type CarouselApi,
} from '@/components/ui/carousel';
import { cn } from '@listening/shared';
import Autoplay from 'embla-carousel-autoplay';
import { useEffect, useState } from 'react';

const slides = [
  {
    name: 'Anna V.',
    profession: 'Masters in Speech Pathology',
    Img: () => (
      <img
        className="relative size-8 rounded-full object-cover object-[0px_-3px]"
        src={annaVPng}
        alt="Anna V."
      />
    ),
    Slide: () => (
      <CarouselItem>
        <div className="self-stretch text-center">
          <span className="text-sm font-normal leading-[25.20px] text-[#667085] dark:text-[#B8C4D6]">
            I’m really picky about AI voices, but{' '}
          </span>
          <span className="text-lg font-medium leading-loose text-[#344054] dark:text-[#B8C4D6]">
            this one was amazing!
          </span>
          <span className="text-sm font-normal leading-[25.20px] text-[#667085] dark:text-[#B8C4D6]">
            {' '}
            Even at 1.5x speed, it was engaging to listen to. It’s a game
            changer to be able to listen while walking!
          </span>
        </div>
      </CarouselItem>
    ),
  },
  {
    name: 'Jayati S.',
    profession: '',
    Img: () => (
      <img
        className="relative size-8 rounded-full object-cover object-[0px_-3px]"
        src={jayatiPng}
        alt="Jayati S."
      />
    ),
    Slide: () => (
      <CarouselItem>
        <div className="self-stretch text-center">
          <span className="text-sm font-normal leading-[25.20px] text-[#667085] dark:text-[#B8C4D6]">
            Having an app that converts{' '}
          </span>
          <span className="text-lg font-medium leading-loose text-[#344054] dark:text-[#B8C4D6]">
            all my reading into voice
          </span>
          <span className="text-sm font-normal leading-[25.20px] text-[#667085] dark:text-[#B8C4D6]">
            {' '}
            is just so convenient and amazing
          </span>
        </div>
      </CarouselItem>
    ),
  },
];
export function CustomerTestimonial() {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap());

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  const currentSlide = slides[current];
  if (!currentSlide) throw Error('No slide');

  const Img = currentSlide.Img;
  return (
    <Carousel
      setApi={setApi}
      opts={{
        loop: true,
      }}
      plugins={[
        Autoplay({
          delay: 4 * 1000,
        }),
      ]}
      className="z-10 flex flex-col items-center justify-center gap-4 rounded-3xl bg-white px-8 py-6 dark:bg-muted"
    >
      <div className="flex items-start justify-start">
        <StarIcon className="size-6" />
        <StarIcon className="size-6" />
        <StarIcon className="size-6" />
        <StarIcon className="size-6" />
        <StarIcon className="size-6" />
      </div>

      <CarouselContent>
        {slides.map(({ Slide, name }) => (
          <Slide key={name} />
        ))}
      </CarouselContent>
      <div className="flex items-center justify-center self-stretch">
        <Button
          variant="ghost"
          onClick={() => api?.scrollPrev()}
          className="flex h-auto origin-top-left items-center justify-start rounded-full border border-[#667085]/10 p-2"
        >
          <ChevronLeft className="size-2 text-[#667085] dark:text-[#B8C4D6]" />
        </Button>
        <div className="flex flex-col items-center justify-center gap-1 px-6">
          <Img />
          <div className="flex flex-col items-center justify-center gap-0.5">
            <div className="text-sm font-semibold leading-[18.90px] text-[#080124] dark:text-white">
              {currentSlide.name}
            </div>
            <div className="text-xs font-normal leading-none text-[#667085] dark:text-[#B8C4D6]">
              {currentSlide.profession}
            </div>
          </div>
        </div>
        <Button
          variant="ghost"
          onClick={() => api?.scrollNext()}
          className="flex h-auto rotate-180 items-center justify-start rounded-full border border-[#667085]/10 p-2"
        >
          <ChevronLeft className="size-2 text-[#667085] dark:text-[#B8C4D6]" />
        </Button>
      </div>
      <div className="flex items-center justify-start gap-1">
        {slides.map((_, idx) => (
          <div
            key={`indicator_${idx.toString()}`}
            className={cn(
              'size-2 rounded-full',
              current == idx ? 'bg-[#b6bac1]' : 'bg-[#d9d9d9]',
            )}
          />
        ))}
      </div>
    </Carousel>
  );
}
